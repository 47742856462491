import React, { forwardRef, SVGAttributes } from "react";
import { useCss, k, a } from "kremling";

import { iconMigrateList } from "../../custom-elements/cps-icon/icon-migrate";

export type CpIconProps = SVGAttributes<SVGElement> & {
  name: string;
  size?: number;
  className?: string;
  fill?: string;
};

export const CpIcon = forwardRef<SVGSVGElement, CpIconProps>(function CpIcon(
  { name, size, className, fill, style, ...rest },
  ref,
) {
  const scope = useCss(css);
  const newName = (iconMigrateList as Record<string, string>)[name];
  if (newName && newName !== name) {
    console.warn(
      `It seems you may be looking for '${newName}' rather than '${name}' for your CpIcon name`,
    );
  }
  return (
    <svg
      ref={ref}
      {...scope}
      className={a(`cp-icon ${name}`).a(className)}
      style={{
        ...(size
          ? { width: `${size / 10}rem`, height: `${size / 10}rem` }
          : {}),
        ...(fill ? { fill } : {}),
        ...(style || {}),
      }}
      viewBox="0 0 24 24"
      {...rest}
    >
      <use href={`#${name}`} xlinkHref={`#${name}`} />
    </svg>
  );
});

const css = k`
  .cp-icon {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }
`;
