import React, { createContext, useContext } from "react";
import { useCss, a } from "kremling";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from "prop-types";

import { CpCheckbox } from "@components";

const CpCheckboxContext = createContext({});

export function CpCheckboxGroup(props) {
  const { children, className, disabled, inline, onBlur, onChange, value } =
    props;
  const scope = useCss(css);
  function update(delta) {
    let newValues = [];
    if (typeof delta === "object") {
      let values = value.filter((val) => val.id !== delta.id);
      newValues = values.length === value.length ? [...value, delta] : values;
    } else {
      newValues = value.includes(delta)
        ? value.filter((v) => v !== delta)
        : [...value, delta];
    }
    onChange(newValues);
  }
  return (
    <CpCheckboxContext.Provider
      value={{ value, onChange: update, onBlur, disabled }}
    >
      <div
        {...scope}
        className={a("cp-checkbox-group")
          .a(className)
          .m("cp-checkbox--inline", inline)}
      >
        {children}
      </div>
    </CpCheckboxContext.Provider>
  );
}

CpCheckbox.propTypes = {
  disabled: bool,
  inline: bool,
  onChange: func,
  value: array,
};

function CheckboxItem({ children, id, disabled, ...props }) {
  const {
    value,
    disabled: parentDisabled,
    onBlur,
    onChange,
  } = useContext(CpCheckboxContext);
  const isChecked =
    typeof id === "object"
      ? value.findIndex((val) => val.id === id.id) >= 0
      : value?.includes(id);
  return (
    <CpCheckbox
      checked={isChecked}
      disabled={typeof parentDisabled === "boolean" ? parentDisabled : disabled}
      onBlur={onBlur}
      onChange={() => onChange(id)}
      {...props}
    >
      {children}
    </CpCheckbox>
  );
}

CheckboxItem.propTypes = {
  id: oneOfType([string, number, object]),
};

CpCheckboxGroup.Item = CheckboxItem;

// language=css
const css = `
  .cp-checkbox--inline {
    display: flex;
    flex-wrap: wrap;
  }

  .cp-checkbox--inline .cp-checkbox {
    margin-right: 2.4rem;
    margin-bottom: .4rem;
  }

  .cp-checkbox--inline .cp-checkbox + .cp-checkbox {
    padding: 0;
  }
`;
