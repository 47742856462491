import React, { isValidElement } from "react";
import { CpIcon, CpIconProps } from "../../cp-icon/cp-icon.component";
import { CpSideMenuButtonProps } from "./cp-side-menu-button.component";

export const renderIcon = (icon: CpSideMenuButtonProps["iconLeft"]) => {
  const iconLeftProps = icon as CpIconProps;
  if (typeof icon === "string") {
    return <CpIcon name={icon} />;
  } else if (typeof iconLeftProps === "object" && iconLeftProps?.name) {
    return <CpIcon {...iconLeftProps} />;
  } else if (isValidElement(icon)) {
    return icon;
  }
};
