import React from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";

import { ModalServiceEntry } from "./modal-service-entry.component";

class ModalService {
  promiseResolveMap = {};
  modalsOpen = 0;
  hideScrollbarClassName = "--modalService-hide-scroll-bar";

  render = (component, props, modalProps) => {
    let onSubmit;
    const id = `__modalService_${uuid()}`;
    const promise = new Promise((resolve) => {
      this.promiseResolveMap[id] = resolve;
      onSubmit = (modalId, data) => {
        resolve(data);
        this.destroy(modalId);
      };
    });
    const div = document.createElement("div");
    div.id = id;
    document.body.appendChild(div);
    ReactDOM.render(
      <ModalServiceEntry
        id={id}
        component={component}
        props={props}
        modalProps={modalProps}
        onSubmit={onSubmit}
      />,
      document.querySelector(`#${id}`),
    );

    // add id to promise so we can use it if they pass it back to destroy it
    promise.__modalServicePromiseResolveId = id;
    return promise;
  };

  destroy = (modalPromise) => {
    let id;

    // destroy single modal from promise
    if (modalPromise && modalPromise.__modalServicePromiseResolveId) {
      id = modalPromise.__modalServicePromiseResolveId;

      // destroy single modal from id
    } else if (typeof modalPromise === "string") {
      id = modalPromise;
    }

    if (!id) return;
    const resolve = this.promiseResolveMap[id];
    const div = document.querySelector(`#${id}`);
    ReactDOM.unmountComponentAtNode(div);
    div.remove();
    resolve?.(false);
    delete this.promiseResolveMap[id];
  };

  // keep track of modals opened/closed (regular modals + modals generated by the modal service)
  // if any modal is open, remove body scrolling
  __openModal = (hideScroll = true) => {
    modalService.modalsOpen++;
    if (modalService.modalsOpen === 1 && hideScroll) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.classList.add(this.hideScrollbarClassName);
      document.body.style.marginRight = `${scrollbarWidth / 10}rem`;
    }
  };
  __closeModal = () => {
    modalService.modalsOpen--;
    if (modalService.modalsOpen === 0) {
      document.body.classList.remove(this.hideScrollbarClassName);
      document.body.style.marginRight = "initial";
    }
  };
}

export const modalService = new ModalService();
