import React, { useEffect, useState, useMemo, useRef } from "react";
import { useCss, k, a } from "kremling";
import { array, func, number, string, object } from "prop-types";
import { range, chunk, isNil } from "lodash";

import { CpButton } from "@components";

CpPagination.propTypes = {
  // common props
  initialPage: number,
  pagesToDisplay: number,
  onChange: func.isRequired,
  className: string,
  // ref to focus after page change
  // defaults to the current page button
  focusRef: object,

  // uncontrolled
  // lastPage is required when data is not provided
  lastPage: number,

  // controlled
  // pass in data to have CpPagination handle paginating the data
  data: array,
  // the number of results per page
  perPage: number,
};

export function CpPagination(props) {
  const firstPage = 0;

  const {
    initialPage = firstPage,
    pagesToDisplay = 5,
    onChange,
    className,
    focusRef,
    data,
    perPage = 20,
  } = props;

  if (!data && isNil(props.lastPage)) {
    console.warn(
      "<CpPagination> either data or lastPage are required! See Storybook for more info.",
    );
  }

  const scope = useCss(css);

  const [currentPage, setCurrentPage] = useState(initialPage);
  const currentButtonRef = useRef({});
  const shouldFocus = useRef(false);

  const paginatedData = useMemo(() => chunk(data, perPage), [data, perPage]);
  const lastPage = props.lastPage || paginatedData.length - 1;

  const shouldCondense = lastPage > pagesToDisplay;

  const pages = useMemo(() => {
    // render all pages when not enough pages to collapse
    if (!shouldCondense) {
      if (lastPage <= 0) {
        return [0];
      } else {
        return range(firstPage, lastPage + 1);
      }
    }

    // on the leftmost side
    if (currentPage + 1 < pagesToDisplay) {
      return range(firstPage, pagesToDisplay);
    }

    // rightmost side
    if (currentPage > lastPage - pagesToDisplay + 1) {
      return range(lastPage - pagesToDisplay + 1, lastPage + 1);
    }

    // inbetween
    // how many pages to render on either side of the current page
    const offset = pagesToDisplay / 2;
    // how many pages should the current page be offset from the center
    // 1 ... 4 _5_ 6 7 ... 10 <- centerOffset = 1
    // 1 ... 4 5 _6_ 7 8 ... 10 <- centerOffset = 0
    const centerOffset = pagesToDisplay % 2 === 0 ? 1 : 0;

    return range(
      currentPage - Math.floor(offset - centerOffset),
      currentPage + Math.round(offset + centerOffset),
    );
    /* Add missing deps and verify it doesn't break: pagesToDisplay, shouldCondense */
  }, [lastPage, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      // when paginatedData[currentPage] returns undefined,
      // it means there is no content to paginate, so return an empty array
      onChange(paginatedData[currentPage] || []);
    } else {
      onChange(currentPage);
    }

    // this prevents focus stealing on mount
    if (shouldFocus.current) {
      focusRef?.current
        ? focusRef.current.focus({ preventScroll: true })
        : currentButtonRef?.current?.focus?.({ preventScroll: true });
    } else {
      shouldFocus.current = true;
    }
  }, [data, paginatedData, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if the current page has no data, set the current page back by 1 if we can
    if (paginatedData && !paginatedData[currentPage] && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    /* Add missing deps and verify it doesn't break: currentPage */
  }, [paginatedData]); // eslint-disable-line react-hooks/exhaustive-deps

  // like a flat CpButton but with special sizing and modified styling
  const renderPageButton = (page) => {
    const isCurrent = currentPage === page;
    return (
      <button
        key={page}
        className={a("page-button")
          .m("page-button--current", isCurrent)
          .m("cps-wt-bold", isCurrent)
          .t("cps-body", "cps-body-sm", isCurrent)}
        onClick={() => setCurrentPage(page)}
        ref={(ref) => {
          if (isCurrent) currentButtonRef.current = ref;
        }}
      >
        {page + 1}
      </button>
    );
  };

  return (
    <div {...scope} className={a("cp-pagination").a(className)}>
      <span className="cps-screenreader">
        Select a page number for more results
      </span>
      <CpButton
        aria-label="First page"
        icon="caret-large-left"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage - 1 < firstPage}
        small
      />
      {shouldCondense && pages[0] !== firstPage && (
        <>
          {renderPageButton(firstPage)}
          {pages[0] - 1 !== firstPage && "..."}
        </>
      )}
      <div className="page-buttons">{pages.map(renderPageButton)}</div>
      {shouldCondense && pages[pages.length - 1] < lastPage && (
        <>
          {pages[pages.length - 1] + 1 !== lastPage && "..."}
          {renderPageButton(lastPage)}
        </>
      )}
      <CpButton
        aria-label="Last page"
        icon="caret-large-right"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage + 1 > lastPage}
        small
      />
    </div>
  );
}

const css = k`
  .cp-pagination {
    display: flex;
    align-items: center;
    color: var(--cps-color-secondary-text);
  }

  .page-buttons {
    display: flex;
    align-items: baseline;
  }

  .page-button {
    background: none;
    border: none;
    cursor: pointer;
    border-radius: .5rem;
  }

  .page-button:hover:not(:active) {
    color: var(--cps-color-flat-button-text);
  }

  .page-button:focus {
    color: var(--cps-color-flat-button-text);
    outline: none;
  }

  .page-button:focus:not(:active) {
    box-shadow: var(--cp-form-focus-state);
  }

  .page-button--current {
    color: var(--cps-color-flat-button-text);
  }
`;
