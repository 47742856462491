import React, { useEffect, useState } from "react";
import { CpModal } from "@components";

export function ModalServiceEntry({
  id,
  component,
  props,
  modalProps,
  onSubmit,
}) {
  const [show, setShow] = useState(true);
  const [data, setData] = useState(false);
  const Component = component;

  useEffect(() => {
    if (!show) setTimeout(() => onSubmit(id, data), 500);
    /* Add missing deps and verify it doesn't break: data, id, onSubmit (memoize or store in ref) */
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  function submit(data) {
    setData(data);
    setShow(false);
  }

  return (
    <CpModal {...modalProps} show={show} onClose={() => submit(false)}>
      <Component onSubmit={submit} {...props} />
    </CpModal>
  );
}
