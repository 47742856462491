export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];
  return (e) => {
    filteredRefs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(e);
      } else {
        ref.current = e;
      }
    });
  };
};
